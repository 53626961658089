import React from "react"
import * as cn from "classnames"
import {graphql, StaticQuery} from "gatsby"
import * as Cookies from "js-cookie"
import policyFile from "../../../static/Polityka_prywatnosci_i_cookies_strony_Fundacji_Sempre_a_Frente.pdf"
import rodoFile from "../../../static/RODO_w_Sempre_2021.pdf"
import regulationsFile from "../../../static/Regulamin_strony_Fundacji_Sempre_a_Frente_2021.pdf"

export default class Footer extends React.Component {
    state = {
        showErrors: false,
        showCookies: false,
        checkboxError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false
    }

    componentDidMount() {
        this.setState({showCookies: !Cookies.get("sempreCookiesAgreement")})
    }

    valid() {
        this.setState({firstNameError: false, lastNameError: false, emailError: false, checkboxError: false, showErrors: false})
        if (!this.footerFirstName.value) {
            this.setState({firstNameError: true, showErrors: true})
        }
        if (!this.footerLastName.value) {
            this.setState({lastNameError: true, showErrors: true})
        }
        if (!this.footerEmail.value) {
            this.setState({emailError: true, showErrors: true})
        }
        if (!this.agree.checked) {
            this.setState({checkboxError: true, showErrors: true})
        }

    }

    sendFormHandler(e) {
        e.preventDefault()
        this.valid()
        setTimeout(() => { this.sendForm(e) }, 500);
    }

    sendForm(e) {
        if(!this.state.showErrors) {
            this.form.submit()
        }
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query FooterQuery{
                        social: datoCmsContactInformation {
                            facebookUrl
                            instagramUrl
                            linkedinUrl
                            youtubeUrl
                            officeTitle
                            officeAddressNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                            officePhone
                            officeEmail
                            officeInfoNode {
                                childMarkdownRemark{
                                    html
                                }
                            }
                        }
                    }
                `}
                render={({social}) => (
                    <div>
                        <footer id="footer" className="footer">
                            <div className="content">
                                <div className="form">
                                    <form ref={(el) => {this.form = el}} action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                                        <input type="hidden" name="oid" value="00D09000001J9cZ"/>
                                        <input type="hidden" name="retURL" value="https://sempre.org.pl/success"/>
                                        <h2>Skontakuj się z nami</h2>
                                        <div className={cn("input", {invalid: this.state.firstNameError})}>
                                            <p>Imię</p>
                                            <input className="input-text" id="first_name" maxLength="40" name="first_name"
                                                   type="text" ref={(el) => {this.footerFirstName = el}} required/>
                                        </div>
                                        <div className={cn("input", {invalid: this.state.lastNameError})}>
                                            <p>Nazwisko</p>
                                            <input className="input-text" id="last_name" maxLength="80" name="last_name"
                                                   type="text" ref={(el) => {this.footerLastName = el}} required/>
                                        </div>
                                        <div className={cn("input", {invalid: this.state.emailError})}>
                                            <p>E-mail</p>
                                            <input className="input-text" id="email" maxLength="80" name="email"
                                                   type="email" ref={(el) => {this.footerEmail = el}} required/>
                                        </div>
                                        <div className="input">
                                            <p>Wiadomość</p>
                                            <textarea className="input-text" id="00N09000006PNZj" name="00N09000006PNZj" maxLength={250}/>
                                        </div>
                                        <div className={cn("input", {invalid: this.state.checkboxError})}>
                                            <label className="checkbox">
                                                <input type="checkbox" className="input-text" id="00N09000006ONr2"
                                                       name="00N09000006ONr2" value="1" ref={(el) => {this.agree = el}}/>
                                                <span>Zgoda na przetwarzanie danych osobowych. <a href={rodoFile}
                                                                                                  target="_blank">Więcej informacji</a> *</span>
                                            </label>
                                        </div>
                                    </form>
                                    {this.state.showErrors && <p className="error">Proszę uzupełnić wymagane pola</p>}
                                    <div>
                                        <button type="submit" name="submit" className="btn" onClick={this.sendFormHandler.bind(this)}>
                                            <span>Wyślij</span>
                                            <i className="icon-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="contact">
                                    <h3>{social.officeTitle}</h3>
                                    <div className="contact__row">
                                        <i className="icon-home"></i>
                                        <pre dangerouslySetInnerHTML={{
                                            __html: social.officeAddressNode.childMarkdownRemark.html,
                                        }}/>
                                    </div>
                                    {social.officeInfoNode.childMarkdownRemark.html &&
                                    <div className="contact__row">
                                        <i className="icon-time"></i>
                                        <pre dangerouslySetInnerHTML={{
                                            __html: social.officeInfoNode.childMarkdownRemark.html,
                                        }}/>
                                    </div>
                                    }
                                    <div className="contact__row">
                                        <i className="icon-call"></i>
                                        <p>Telefon: {social.officePhone}</p>
                                    </div>
                                    <div className="contact__row">
                                        <i className="icon-email"></i>
                                        <p>Email: <a href={`mailto:${social.officeEmail}`}>{social.officeEmail}</a></p>
                                    </div>
                                    <div className="contact__row">
                                        <i className="icon-opp"></i>
                                        <p>KRS 0000356733</p>
                                    </div>
                                    <div className="contact__row">
                                        <i className="icon-piggy-bank"></i>
                                        <p>Nr konta: BNP Paribas 03 2030 0045 1110 0000 0198 8260</p>
                                    </div>
                                    <div className="social">
                                        {social.facebookUrl &&
                                        <a href={social.facebookUrl} title="Facebook" target="_blank"><i
                                            className="icon-facebook"></i></a>}
                                        {social.instagramUrl &&
                                        <a href={social.instagramUrl} title="Instagram" target="_blank"><i
                                            className="icon-instagram"></i></a>}
                                        {social.linkedinUrl &&
                                        <a href={social.linkedinUrl} title="Linkedin" target="_blank"><i
                                            className="icon-linkedin"></i></a>}
                                        {social.youtubeUrl &&
                                        <a href={social.youtubeUrl} title="Youtube" target="_blank"><i
                                            className="icon-youtube"></i></a>}
                                    </div>
                                    <i className="icon-paper-plane"></i>
                                </div>
                            </div>
                        </footer>
                        <div className="bottom">
                            <p>Sempre © 2020 / All Rights Reserved</p>
                            <a href={regulationsFile} target="_blank">Regulamin strony i wpłat</a>
                            <a href={rodoFile} target="_blank">RODO</a>
                        </div>
                        <div className={cn("cookies", {active: this.state.showCookies})}>
                            <div className="content">
                                <img src="sempre-sm.png" alt=""/>
                                <p>Strona używa plików cookies w celu zapewnienia jej prawidłowego działania oraz w
                                    celach statystycznych. Warunki przechowywania plików cookies możesz określić w
                                    swojej przeglądarce. Więcej informacji znajdziesz w&nbsp;
                                    <a href={policyFile} target="_blank">Polityce Prywatności i Cookies</a>.</p>
                                <span className="btn btn-yellow btn-sm" onClick={() => {Cookies.set('sempreCookiesAgreement', true), this.setState({showCookies: false})}}>OK</span>
                            </div>
                        </div>
                    </div>
                )}
            />
        )
    }
}
