import React from "react"
import * as cn from "classnames"
import {graphql, StaticQuery} from "gatsby"
import logo from "../../../static/sempre.png"
import fbIcon from "../../../static/facebook.png"
import instagramIcon from "../../../static/instagram.png"
import linkedinIcon from "../../../static/linkedin.png"
import Dropdown from 'react-bootstrap/Dropdown'

export default class Header extends React.Component {
    state = {
        showMenu: false,
        showMobileMeu: false,
        name: ''
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
    }

    handleScroll() {
        if (window.scrollY > 50) {
            this.setState({ name: "solid" })
        }
        else {
            this.setState({ name: ""})
        }
    }

    renderMenuItem(page, idx) {
        return (
            <Dropdown.Item key={idx} href={`/${page.node.slug}`}>{page.node.header}</Dropdown.Item>
        )
    }

    getPages(parent, pagesItem) {
        let pages = pagesItem.edges.filter(({node: page}) => page.parentMenu != null)
        return pages.filter(({node: item}) => item.parentMenu.label === parent).map((page, idx) => this.renderMenuItem(page, idx))
    }

    render() {
        const s = this.state

        return (
            <StaticQuery
                query={graphql`
                    query HeaderQuery{
                        mainMenuItem: allDatoCmsMainMenu(
                            sort: {
                              fields: [order]
                              order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    order
                                    label
                                    noChildren
                                    slug
                                }
                            }
                        }
                        pagesItem: allDatoCmsPage(
                            sort: {
                              fields: [order]
                              order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    header
                                    slug
                                    showOnMenu
                                    parentMenu {
                                        label
                                    }
                                }
                            }
                        }
                        customPageItems: allDatoCmsCustomPage(
                            sort: {
                              fields: [order]
                              order: ASC
                            }
                        ) {
                            edges {
                                node {
                                    name
                                    header
                                    slug
                                    parentMenu {
                                        label
                                    }
                                }
                            }
                        }
                        social: datoCmsContactInformation {
                            facebookUrl
                            instagramUrl
                            linkedinUrl
                            youtubeUrl
                        }
                    }
                `}
                render={({mainMenuItem, pagesItem, customPageItems, social}) => (
                    <header className={cn("header", s.name)}>
                        <div className="content">
                            <a href="/" className="logo">
                                <img src={logo} alt="" />
                            </a>
                            <div className="btn-mobile" onClick={this.props.onClick}>
                                <span></span>
                            </div>
                            <nav>
                                <div className="mobile-back" onClick={this.props.onMobileClick}>
                                    Powrót
                                </div>
                                <ul>
                                    {mainMenuItem.edges.map(({ node: item }) => (
                                        <li key={item.order} className="more">
                                            {!item.noChildren &&
                                                <Dropdown onClick={this.props.onMobileClick}>
                                                    <Dropdown.Toggle>
                                                        {item.label}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {this.getPages(item.label, pagesItem)}
                                                        {this.getPages(item.label, customPageItems)}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                            {item.noChildren &&
                                                <a href={`/${item.slug}`}>{item.label}</a>
                                            }
                                        </li>
                                    ))}
                                    <li><a href="/wesprzyj-nas" title="Pomóż" className="yellow"><i className="icon-heart"></i> Pomóż</a></li>
                                </ul>
                                <div className="social">
                                    {social.facebookUrl && <a href={social.facebookUrl} title="Facebook" target="_blank"><i className="icon-facebook"></i></a>}
                                    {social.instagramUrl && <a href={social.instagramUrl} title="Instagram" target="_blank"><i className="icon-instagram"></i></a>}
                                    {social.linkedinUrl && <a href={social.linkedinUrl} title="Linkedin" target="_blank"><i className="icon-linkedin"></i></a>}
                                    {social.youtubeUrl && <a href={social.youtubeUrl} title="Youtube" target="_blank"><i className="icon-youtube"></i></a>}
                                </div>
                            </nav>
                        </div>
                        <div className="fixed-social">
                            {social.facebookUrl && <a href={social.facebookUrl} title="Facebook" target="_blank"><img src={fbIcon} alt="Facebook" /></a>}
                            {social.instagramUrl && <a href={social.instagramUrl} title="Instagram" target="_blank"><img src={instagramIcon} alt="Instagram" /></a>}
                            {social.linkedinUrl && <a href={social.linkedinUrl} title="Linkedin" target="_blank"><img src={linkedinIcon} alt="Linkedin" /></a>}
                        </div>
                        <div className="fixed-actions">
                            <a href="http://www.1procent.sempre.org.pl/" target="_blank" title="Przekaż 1% Podatku">1%</a>
                            <a href="/wesprzyj-nas" title="Wesprzyj Nas">
                                <i className="icon-heart"></i>
                                <span>Wesprzyj nas</span>
                            </a>
                        </div>
                    </header>
                )}
            />
        )
    }
}