import React from "react"
import * as cn from "classnames"
import Header from "./layout/Header"
import Footer from "./layout/Footer"
import SimpleReactLightbox from "simple-react-lightbox"
import {Helmet} from "react-helmet"
import "../style/style.scss"
import "../fonts/icomoon/style.css"

export default class Layout extends React.Component {
    state = {
        isMounted: false,
        menuVisible: false,
        subMenuVisible: false
    }

    componentDidMount() {
        this.setState({ isMounted: true })
    }

    render() {
        if (!this.state.isMounted) {
            return null
        }

        return (
            <div className={cn(this.props.className, this.state.menuVisible && "menu-visible",  this.state.subMenuVisible && "sub-menu-visible")}>
                <Helmet>
                    <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Open+Sans:wght@400;700&display=swap" rel="stylesheet" />
                </Helmet>
                <SimpleReactLightbox>
                    <Header onClick={() => this.setState({menuVisible: !this.state.menuVisible})} onMobileClick={() => this.setState({subMenuVisible: !this.state.subMenuVisible})}></Header>
                    {this.props.children}
                    <Footer></Footer>
                </SimpleReactLightbox>
            </div>
        )
    }
}